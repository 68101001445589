<template>
  <header class="header">
    <div class="header__left">
      <img alt="logo" class="header__left__img" src="@/assets/logo/color.svg" @click="gotoMain" />
      <div :class="`header__left__menu ${menuOpened ? 'open' : ''}`">
        <router-link active-class="header__left__menu__link--active" class="header__left__menu__link" to="/patients"
          >Patients
        </router-link>
        <router-link active-class="header__left__menu__link--active" class="header__left__menu__link" to="/employers"
          >Employers
        </router-link>
        <router-link active-class="header__left__menu__link--active" class="header__left__menu__link" to="/brokers"
          >Brokers
        </router-link>
        <router-link active-class="header__left__menu__link--active" class="header__left__menu__link" to="/about"
          >Who we are
        </router-link>
      </div>
    </div>
    <div class="header__right">
      <div :class="`header__left__menu-btn ${menuOpened ? 'open' : ''}`" @click="toggleMenu">
        <div class="header__left__menu-btn__burger"></div>
      </div>
      <div :class="`d-none header__right__lang ${menuOpened ? 'open' : ''}`">
        <v-icon>{{ `$vuetify.icons.${selectedLang.toLowerCase()}` }}</v-icon>
        <v-select
          v-model="selectedLang"
          :items="langs"
          append-icon=""
          class="header__right__lang__select"
          dense
          flat
          height="40px"
          solo
        >
          <template v-slot:item="data">
            <v-icon class="mr-2">{{ `$vuetify.icons.${data.item.value.toLowerCase()}` }}</v-icon>
            {{ data.item.text }}
          </template>
        </v-select>
        <div class="header__right__lang__label">{{ selectedLang }}</div>
      </div>
      <v-btn
        :class="`header__right__login landing-btn ${menuOpened ? 'open' : ''}`"
        color="primary"
        dark
        depressed
        @click="gotoLogin"
        >Log In
      </v-btn>
    </div>
  </header>
</template>

<script>
export default {
  name: "LandingHeader",
  data() {
    return {
      langs: [
        {
          text: "English",
          value: "EN",
        },
        {
          text: "Español",
          value: "ES",
        },
      ],
      selectedLang: "EN",
      menuOpened: false,
    };
  },
  methods: {
    gotoMain() {
      this.$router.push("/");
    },
    gotoLogin() {
      window.location = `${process.env.VUE_APP_DASHBOARD_URL}/auth/signin`;
    },
    toggleMenu() {
      this.menuOpened = !this.menuOpened;
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  font-size: 18px;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 15px 40px;
  position: relative;
  z-index: 3;

  &__left {
    display: flex;
    align-items: center;

    &__menu {
      @media (min-width: 1024px) {
        min-width: 480px;
      }
      @media (max-width: 1024px) {
        height: var(--doc-height);
        width: 300px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        position: absolute;
        left: -300px;
        top: 0;
        transition: 0.5s ease;
        padding: 110px 40px 40px;

        &.open {
          left: 0;
          -webkit-box-shadow: 30px 0 40px 0 rgba(0, 0, 0, 0.5);
          -moz-box-shadow: 30px 0 40px 0 rgba(0, 0, 0, 0.5);
          box-shadow: 30px 0 40px 0 rgba(0, 0, 0, 0.5);
        }
        &__link {
          margin-bottom: 20px;

          &:hover {
            color: $primary;
          }
        }
      }
      @media (max-width: 480px) {
        width: 100%;
        left: -100%;
        align-items: center;
      }

      &__link {
        margin-left: 25px;
        text-decoration: none;
        color: $primaryblack2;
        font-weight: bold;
        transition: 0.2s ease;
        position: relative;
        @include extraLarge {
          margin-left: 20px;
        }

        &:before {
          content: "";
          position: absolute;
          width: 100%;
          height: 4px;
          bottom: -8px;
          left: 0;
          background-color: $primary;
          opacity: 0;
          transition: 0.2s ease;
        }

        &:hover {
          color: $primary;

          &:before {
            opacity: 1;
          }
        }

        &--active {
          color: $primary;

          &:before {
            opacity: 1;
          }
        }

        @media (max-width: 480px) {
          margin-left: 0;
        }
      }
    }

    &__img {
      height: 50px;
      cursor: pointer;
      z-index: 1;
    }

    &__menu-btn {
      position: relative;
      display: none;
      justify-content: center;
      align-items: center;
      width: 80px;
      height: 50px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      @media (max-width: 1024px) {
        display: flex;
      }

      &__burger {
        width: 45px;
        height: 5px;
        background-color: $primary;
        border-radius: 5px;
        transition: all 0.3s ease-in-out;

        &:before,
        &:after {
          content: "";
          position: absolute;
          width: 45px;
          height: 5px;
          background: $primary;
          border-radius: 5px;
          transition: all 0.3s ease-in-out;
        }

        &:before {
          transform: translateY(-14px);
        }

        &:after {
          transform: translateY(14px);
        }
      }

      &.open {
        .header__left__menu-btn__burger {
          transform: translateX(-45px);
          background: transparent;
          box-shadow: none;

          &:before {
            transform: rotate(45deg) translate(35px, -35px);
          }

          &:after {
            transform: rotate(-45deg) translate(35px, 35px);
          }
        }
      }
    }
  }

  &__right {
    display: flex;
    align-items: center;

    &__lang {
      width: 84px;
      padding: 0 36px 0 14px;
      margin-right: 30px;
      position: relative;
      height: 40px;
      background: #fff;
      z-index: 3;
      display: flex;
      align-items: center;

      @media (max-width: 1024px) {
        position: absolute;
        left: calc(-30% + 60px);
        top: 300px;
        transition: all 0.5s ease;

        @media (max-width: 480px) {
          left: calc(-100% + 60px);
        }
        &.open {
          left: 60px;

          @media (max-width: 480px) {
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }

      &__select {
        padding: 0;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
      }

      &__label {
        position: absolute;
        right: 0;
        top: 6px;
        width: 100%;
        pointer-events: none;
        text-align: right;
        padding: 0 14px;
        z-index: 4;
      }
    }

    &__login {
      @media (max-width: 1024px) {
        top: calc(var(--doc-height) - 78px);
        position: absolute;
        left: calc(-300px + 40px);
        transition: 0.5s ease;

        &.open {
          left: 40px;

          @media (max-width: 480px) {
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
  }
}
</style>
